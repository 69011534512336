@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

li,
a,
button {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #24252a;
  text-decoration: none;
}
.circle {
  border-radius: 50%;
}
.content-wrap {
  flex: 1;
}
.mg-l {
  margin-left: 20px;
}
.shadow-4 {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}
.nohand:hover {
  cursor: default;
}
.button {
  padding: 9px 25px;
  background-color: rgb(0, 102, 255);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.3s ease 0s;
}
.button:hover {
  background-color: rgba(0, 102, 255, 0.8);
}
.bold {
  font-weight: 700;
}

.lightSection {
  background-color: #eff1f5;
  width: 100%;
}

.darkSection {
  display: flex;
  flex-direction: column;
  background-color: rgb(36, 36, 36);
  width: 100%;
  padding: 2% 0px;
  align-items: center;
}

::selection {
  background-color: #ffd073;
}

@media (max-width: 480px) {
  .button {
    margin-left: 0px;
    padding: 12px 20px;
  }
}
